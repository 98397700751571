<template>
<div class="signup">
  <div class="logo-container">
    <img src="/images/logo-2.svg" loading="lazy" alt="Amorino Logo" class="logo-image" @click="$router.push('/')"/>
  </div>
  <div class="section section-return">
    <div class="small-container">
      <div class="return-action" @click="$router.back()">
        ← REGRESAR
      </div>
    </div>
  </div>
  <div class="section section-plan" v-if="!finished">
    <div class="small-container">
      <h1 class="benefits-header">¿Olvidaste tu contraseña?</h1>
      <div class="account-data-container">
        <div class="account-data">
          <div class="label">
            <div class="label-text">
              Email
            </div>
            <input class="account-input" type="text" v-model="payload.email">
          </div>
          <div class="action" @click="login">
            RECUPERAR CONTRASEÑA
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section section-plan" v-else>
    <div class="small-container">
      <h1 class="benefits-header">Revisa tu correo electrónico</h1>
      <div class="account-data-container">
        <div class="account-data">
          <div class="action" @click="$router.push('/login')">
            IR A INICIAR SESIÓN
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="small-container">
      <div class="help">
        Si tienes alguna pregunta, escríbenos por WhatsApp a <a data-v-31534ce4="" href="https://wa.me/17085068383">+1.708.506.83.83</a>
      </div>
    </div>
  </div>
  <div class="marquee">
    <div class="track track-front">
      <div class="marquee-text">EXCLUSIVIDAD</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">INVITACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">CELEBRACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">PREFERENCIA</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">EXCLUSIVIDAD</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">INVITACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">CELEBRACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">PREFERENCIA</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">EXCLUSIVIDAD</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">INVITACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">CELEBRACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">PREFERENCIA</div>
      <div class="marquee-text">·</div>
    </div>
  </div>
</div>
</template>

<script>
import ky from 'ky';

export default {
  data() {
    return {
      payload: {
        email: '',
      },
      finished: false,
    };
  },
  methods: {
    async login() {
      await ky.post(
        `${process.env.VUE_APP_BACKEND_URL}/forgot-password`,
        {
          json: this.payload,
        },
      );

      this.finished = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px 0;
}
.section-return {
  padding: 16px;
  font-size: 16px;
}
.help {
  width: 100%;
  text-align: center;
  padding: 12px 0;
}
.return-action {
  cursor: pointer;
}
.info {
  font-size: 18px;
  text-align: center;
}
.action {
  display: block;
  text-align: center;
  padding: 12px 12px;
  width: 40%;
  background-color:black;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 100px;
  margin: 40px auto 0 auto;
}
.section-plan {
  padding: 10px 0;
}
.account-data-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.account-data {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}
.account-input {
  display: block;
  width: 100%;
  padding: 12px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  background-color: #ffffff;
  border: 2px dotted #fde8dc;
  border-radius: 8px;
  font-size: 16px;
}
.label {
  margin-bottom: 14px;
}
.label-text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
}
.action {
  display: block;
  text-align: center;
  padding: 12px 12px;
  width: 100%;
  background-color:black;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 100px;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
